exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-articles-articles-2005-contest-1-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/articles/articles/2005-contest-1.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-articles-articles-2005-contest-1-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-articles-articles-2009-5-years-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/articles/articles/2009-5-years.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-articles-articles-2009-5-years-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-articles-articles-2011-muse-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/articles/articles/2011-muse.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-articles-articles-2011-muse-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-articles-articles-2011-woman-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/articles/articles/2011-woman.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-articles-articles-2011-woman-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-articles-articles-2014-anniversary-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/articles/articles/2014-anniversary.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-articles-articles-2014-anniversary-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-articles-articles-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/articles/articles.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-articles-articles-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-articles-club-charter-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/articles/club/charter.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-articles-club-charter-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-articles-club-contract-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/articles/club/contract.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-articles-club-contract-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-articles-club-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/articles/club.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-articles-club-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-articles-club-publications-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/articles/club/publications.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-articles-club-publications-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-articles-club-service-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/articles/club/service.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-articles-club-service-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-articles-concerts-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/articles/concerts.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-articles-concerts-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-articles-contact-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/articles/contact.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-articles-contact-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-articles-grunski-2023-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/articles/grunski2023.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-articles-grunski-2023-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-articles-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/articles/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-articles-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-articles-news-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/articles/news.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-articles-news-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-articles-participants-list-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/articles/participants/list.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-articles-participants-list-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-articles-participants-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/articles/participants.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-opt-build-repo-articles-participants-mdx" */)
}

